import { CircularProgress, Paper, Snackbar, Typography } from "@mui/material";
import { useEffect } from "react";
import { useBoolean } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { tokens } from "locales/tokens";
import { Dialog } from "~served/ui-comps";
import { useIsLatest, useSetIsLatest } from "~/store";
declare global {
  interface Window {
    workbox: {
      messageSkipWaiting(): void;
      register(): void;
      addEventListener(name: string, callback: (e: {
        type: string;
        payload: any;
        isUpdate: boolean;
      }) => unknown);
    };
  }
}
const AppUpdateChecker = () => {
  const {
    value: isDownloading,
    toggle: toggleIsDownloading
  } = useBoolean(false);
  const isLatest = useIsLatest();
  const setIsLatest = useSetIsLatest();
  const {
    t
  } = useTranslation();

  // https://developer.chrome.com/docs/workbox/modules/workbox-window#type-WorkboxLifecycleEventMap
  useEffect(() => {
    if ("serviceWorker" in navigator && window.workbox !== undefined) {
      const wb = window.workbox;
      const handleLog = console.log;
      wb.addEventListener("installing", handleLog);
      wb.addEventListener("message", handleLog);
      wb.addEventListener("installed", e => {
        handleLog(e);
        if (e.isUpdate) toggleIsDownloading();
      });
      wb.addEventListener("redundant", handleLog);
      wb.addEventListener("activating", handleLog);
      wb.addEventListener("controlling", handleLog);
      wb.addEventListener("waiting", handleLog);
      wb.addEventListener("activated", e => {
        handleLog(e);
        if (e.isUpdate) {
          toggleIsDownloading();
          setIsLatest(false);
        }
      });
      wb.register();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>
			{isDownloading && <Snackbar open>
					<Paper variant="elevation" elevation={12} sx={{
        p: 2,
        display: "flex",
        gap: 1
      }}>
						<CircularProgress size={24} />
						<Typography variant="body2">Installing updates</Typography>
					</Paper>
				</Snackbar>}

			{!isLatest && <Dialog dialogProps={{
      maxWidth: "xs"
    }} title={`🎉 ${t(tokens.global.newerAppVersionAvailable)}`} actionButtonProps={{
      children: `${t(tokens.global.reloadPage)}`,
      onClick: () => window.location.reload()
    }} />}
		</>;
};
export { AppUpdateChecker };