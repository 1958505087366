import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { useEffect } from "react";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "offlineFirst",
      cacheTime: Infinity,
      retry: false
    },
    mutations: {
      networkMode: "offlineFirst",
      cacheTime: Infinity,
      retry: false
    }
  }
});
const persister = createSyncStoragePersister({
  storage: typeof window !== "undefined" ? window.sessionStorage : undefined
});
const QueryProvider = ({
  children
}) => {
  useEffect(() => {
    window.$queryClient = queryClient;
  }, []);
  return <PersistQueryClientProvider client={queryClient} persistOptions={{
    persister
  }} data-sentry-element="PersistQueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="QueryProvider.tsx">
			{children}
		</PersistQueryClientProvider>;
};
export { QueryProvider };
declare global {
  interface Window {
    /** App query client instance, available after app mounted.
     * ONLY USE THIS WHEN HOOKS IS INACCESSIBLE.
     * */
    $queryClient: QueryClient;
  }
}