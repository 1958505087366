import {
	DiscountOutlined,
	InventoryOutlined,
	ManageSearchOutlined,
	MenuBookOutlined,
	NoteAltOutlined,
	PersonPinOutlined,
	PointOfSaleOutlined,
	ReceiptOutlined,
	SettingsOutlined,
	TableChartOutlined,
} from "@mui/icons-material";

const PALETTES = [
	"#987196",
	"#026979",
	"#715B64",
	"#69385C",
	"#E29279",
	"#DE6C83",
	"#70A5FF",
	"#D54864",
	"#987196",
	"#937682",
	"#06895D",
	"#8197A0",
];

const ROUTES = [
	{
		icon: NoteAltOutlined,
		pathname: "/ops",
		asPath: "/ops",
		text: "ops",
		siblings: [
			"/ops/orders",
			"/ops/till",
			"/ops/locations",
			"/ops/prep-stations",
			"/ops/reservations",
		],
	},
	{
		icon: InventoryOutlined,
		pathname: "/inventories/raw-material-recieved",
		asPath: "/inventories/raw-material-recieved",
		text: "inventories",
		roles: [],
		siblings: [
			"/inventories/raw-materials",
			"/inventories/units-of-measurement",
			"/inventories/suppliers",
		],
		venueEnabledFeature: "is_inventory_management_enabled",
	},
	{
		icon: MenuBookOutlined,
		pathname: "/menus",
		asPath: "/menus",
		text: "menus",
	},
	{
		icon: ReceiptOutlined,
		pathname: "/printer-logs",
		asPath: "/printer-logs",
		text: "receipts",
	},
	{
		icon: ManageSearchOutlined,
		pathname: "/order-logs",
		asPath: "/order-logs",
		text: "orders",
	},
	{
		icon: TableChartOutlined,
		pathname: "/reports/summary",
		asPath: "/reports/summary",
		text: "reports",

		siblings: [
			"/reports/customs",
			"/reports/receipts",
			"/reports/categories",
			"/reports/items",
			"/reports/locations",
			"/reports/staffs",
			"/reports/details",
		],
	},
	{
		icon: PersonPinOutlined,
		pathname: "/customers",
		asPath: "/customers",
		text: "customers",
	},
	{
		icon: DiscountOutlined,
		pathname: "/offers",
		asPath: "/offers",
		text: "offers",
	},
	{
		icon: PointOfSaleOutlined,
		pathname: "/accounting-journals",
		asPath: "/accounting-journals",
		text: "purchase",
		venueEnabledFeature: "is_accounting_journal_enabled",
	},
	{
		icon: SettingsOutlined,
		pathname: "/settings",
		asPath: "/settings",
		text: "settings",
		roles: ["super_admin", "admin", "manager"],
	},
];

const LOCAL_STORAGE_KEYS = {
	TOKEN: "served:staff:token",
	DENOMINATOR: "served:staff:denominator",
	VENUE_ID: "served:staff:venueId",
	PREFERRED_LOCATION: "served:staff:preferredLocation",
	PREFERRED_LOCATION_VIEW: "served:staff:preferredLocationView",
	PREFERRED_CALENDAR_VIEW: "served:staff:preferredCalendarView",
	PREFERRED_LOCATION_ON_TILL: "served:staff:preferredLocationOnTill",
	TILL_STATES: "served:staff:tillStates",
	LANGUAGE: "served:staff:language",
	KPAY_TERMINAL_IP: "served:staff:kpayTerminalIp",
};

export { LOCAL_STORAGE_KEYS, PALETTES, ROUTES };
