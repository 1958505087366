import { useQuery } from "@tanstack/react-query";

import { hubGqlClient } from "~/libs/gql";
import { useOrdersLogFilters } from "~/store";
import { graphql } from "~/types/__generated/gql";
import {
	GetOrdersQuery,
	GetOrdersQueryVariables,
} from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetOrders(
		$from: Int!
		$size: Int!
		$indices: [Int!]
		$date: DateInput
	) {
		getOrders(from: $from, size: $size, indices: $indices, date: $date) {
			total
			items {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				status
				is_paid
				cancelled_reason
				source
				prep_time
				currencies_configs
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_needing_payment_confirmation
				ticket_linebreaks

				_receipt {
					_id
					created_at
					updated_at
					payment_types {
						payment_type
						code
						amount
					}
				}
				_location {
					_id
					name
				}
			}
		}
	}
`;

export type GetOrdersVariables = GetOrdersQueryVariables;

export type GetOrdersResults = GetOrdersQuery["getOrders"];

export const getOrdersQueryKey = (variables: GetOrdersVariables) => [
	"orders",
	variables,
];

export const useGetOrders = (enabled?: boolean) => {
	const variables = useOrdersLogFilters();

	return useQuery({
		enabled:
			enabled ??
			(variables.date ? !!variables.date.from && !!variables.date.to : true),
		queryKey: getOrdersQueryKey(variables),
		queryFn: () =>
			hubGqlClient
				.request(graphql(query), variables)
				.then((res) => res.getOrders),
	});
};

export const useGetOrdersCached = () => {
	return useGetOrders(false);
};
