import { tokens } from "../tokens";

export const zh = {
	[tokens.sidePanel.ops]: "運作管理",
	[tokens.sidePanel.inventories]: "庫存管理",
	[tokens.sidePanel.menus]: "商品管理",
	[tokens.sidePanel.receipts]: "收據管理",
	[tokens.sidePanel.orders]: "訂單管理",
	[tokens.sidePanel.reports]: "數據報告",
	[tokens.sidePanel.customers]: "客戶管理",
	[tokens.sidePanel.offers]: "優惠管理",
	[tokens.sidePanel.purchase]: "採購管理",
	[tokens.sidePanel.settings]: "設定",
	[tokens.sidePanel.openCashdrawer]: "Open Cashdrawer",
	[tokens.searchBar.orderNumber]: "單號",
	[tokens.searchBar.locationName]: "下單位置名",
	[tokens.searchBar.itemName]: "商品名稱",
	[tokens.nav.till]: "收銀台",
	[tokens.nav.orders]: "訂單",
	[tokens.nav.locations]: "下單位置",
	[tokens.nav.kds]: "KDS",
	[tokens.nav.reservations]: "預訂",
	[tokens.ops.orders.placed]: "已下單",
	[tokens.ops.orders.preparing]: "準備中",
	[tokens.ops.orders.processed]: "已完成",
	[tokens.ops.orders.unpaid]: "未付款",
	[tokens.ops.orders.paid]: "已付款",
	[tokens.ops.orders.d]: "日",
	[tokens.ops.orders.h]: "小時",
	[tokens.ops.orders.items]: "商品",
	[tokens.ops.orders.cancelledItems]: "取消商品",
	[tokens.ops.orders.of]: "/",
	[tokens.ops.orders.prepared]: "準備完",
	[tokens.ops.orders.orderCreated]: "創造時間",
	[tokens.ops.orders.movedToPreparing]: "移到準備單",
	[tokens.ops.orders.customerAcceptPrepTime]: "客戶以接受更新時間",
	[tokens.ops.orders.customerRejectPrepTime]: "客戶拒絕更新時間",
	[tokens.ops.orders.prepTimeUpdate]: "更新準備時間",
	[tokens.ops.orders.autoCancelledAllItems]: "所有商品取消，原本訂單自動取消",
	[tokens.ops.orders.autoCancelledAllSplitItems]:
		"所有商品做分單付款，原本訂單自動取消",
	[tokens.ops.orders.autoCancelledAllMovedItems]:
		"所有商品移到另外的訂單，原本訂單自動取消",
	[tokens.ops.orders.quantityChangeFrom]: "數量已更改從",
	[tokens.ops.orders.quantityChangeTo]: "到",
	[tokens.ops.orders.cancelled]: "取消",
	[tokens.ops.orders.discountChangeFrom]: "訂單折扣從",
	[tokens.ops.orders.discountChangeTo]: "改到",
	[tokens.ops.orders.splitOf]: "從",
	[tokens.ops.orders.splitAll]: "全部",
	[tokens.ops.orders.splited]: "已分單付款",
	[tokens.ops.orders.moveOf]: "從",
	[tokens.ops.orders.moveAll]: "全部",
	[tokens.ops.orders.moved]: "移到新的下單位置",
	[tokens.ops.orders.movedToProcessed]: "員工標記為完成",
	[tokens.ops.orders.markedAsPaid]: "員工標記為付款",
	[tokens.ops.orders.orderCancelled]: "取消訂單",
	[tokens.ops.orders.add]: "加單",
	[tokens.ops.orders.edit]: "改單",
	[tokens.ops.orders.takePayment]: "收錢",
	[tokens.ops.orders.processOrder]: "完成訂單",
	[tokens.ops.orders.prepareOrder]: "準備訂單",
	[tokens.ops.orders.printKitchenMessage]: "通知廚房",
	[tokens.ops.orders.printBill]: "買單",
	[tokens.ops.orders.printTicket]: "打印廚房票單",
	[tokens.ops.orders.printChecklist]: "打印訂單清單",
	[tokens.ops.orders.printCancelledTicket]: "打印已取消的蛋",
	[tokens.ops.orders.kitchenMessageContent]: "通知內容",
	[tokens.ops.orders.messageIsRequired]: "輸入通知",
	[tokens.ops.orders.cancelOrder]: "取消單",
	[tokens.ops.orders.cancelOrderModel]: "取消訂單",
	[tokens.ops.orders.cancelOrderModelDesc]: "取消訂單的操作無法還原",
	[tokens.ops.orders.subTotal]: "小計",
	[tokens.ops.orders.offer]: "優惠",
	[tokens.ops.orders.discount]: "折扣",
	[tokens.ops.orders.vat]: "VAT",
	[tokens.ops.orders.service]: "服務費",
	[tokens.ops.orders.adjustment]: "調整",
	[tokens.ops.orders.grandTotal]: "總數",
	[tokens.ops.orders.locationName]: "下單位置名",
	[tokens.ops.locations.showAllLocation]: "全部下單位置",
	[tokens.ops.locations.only]: "",
	[tokens.ops.locations.showing]: "",
	[tokens.ops.locations.locations]: "下單位置",
	[tokens.ops.locations.switchToListView]: "表格圖",
	[tokens.ops.locations.switchToFloorPlan]: "平面圖",
	[tokens.ops.locations.createNewLocation]: "新增下單位置",
	[tokens.ops.locations.editLayout]: "更改下單位置",
	[tokens.ops.locations.locationAvailable]: "下單位置可用的",
	[tokens.ops.locations.addItem]: "下單",
	[tokens.ops.locations.markedOccupied]: "標記為使用",
	[tokens.ops.locations.markedAvailable]: "標記為可用",
	[tokens.ops.locations.defaultOrderNote]: "下單位置備註",
	[tokens.ops.locations.editThisLocation]: "更改下單位置",
	[tokens.ops.locations.openConsumerApp]: "打開網上下單",
	[tokens.ops.locations.getOrderQR]: "二維碼",
	[tokens.ops.locations.deleteThisLocation]: "刪除下單位置",
	[tokens.ops.locations.totalVat]: "總銷售稅",
	[tokens.ops.locations.totalService]: "總服務費",
	[tokens.ops.locations.totalBill]: "總共訂單金額",
	[tokens.ops.locations.totalPaid]: "已付",
	[tokens.ops.locations.totalUnpaid]: "未付",
	[tokens.ops.locations.moveOrders]: "移動訂單",
	[tokens.ops.locations.moveItems]: "移商品",
	[tokens.ops.locations.printChecklist]: "打印訂單清單",
	[tokens.ops.locations.split]: "分開付款",
	[tokens.ops.locations.kitchenMessage]: "通知廚房",
	[tokens.ops.locations.sendKitchenMessage]: "通知廚房",
	[tokens.ops.locations.addObjectToLayout]: "調整平面圖",
	[tokens.ops.locations.doneEditLayout]: "完成調整平面圖",
	[tokens.ops.locations.printBill]: "打印帳單",
	[tokens.ops.locations.selectNewLocation]: "選擇新的下單位置",
	[tokens.ops.locations.categoryDisableMessage]: "QR下單時不顯示這些商品品類",
	[tokens.ops.locations.itemTypeDisableMessage]: "QR下單時不顯示這些商品類型",
	[tokens.ops.locations.itemDisableMessage]: "QR下單時不顯示這些商品",
	[tokens.ops.locations.payNowMustBeAllowedMessage]:
		"開通立即支付才可以選擇支付方式",
	[tokens.ops.locations.allowPayNow]: "開通立即支付",
	[tokens.ops.locations.allowPayLater]: "開通下單後支付",
	[tokens.ops.locations.moveOrderOutOfPrefix]: "移動",
	[tokens.ops.locations.moveOrderOutOfSuffix]: "的訂單到",
	[tokens.ops.locations.moveItemOutOfPrefix]: "移動",
	[tokens.ops.locations.moveItemOutOfSuffix]: "的商品到",
	[tokens.ops.locations.newLocationIdRequired]: "必須輸入資料欄",
	[tokens.ops.locations.allOrders]: "訂單",
	[tokens.ops.locations.howDoYouWantToSplitThisTab]: "你想怎麼樣分開付款？",
	[tokens.ops.locations.equally]: "按人數",
	[tokens.ops.locations.amoungHowManyPeopleWillThisTabBeSplitted]:
		"輸入人數數量",
	[tokens.ops.locations.eachPersonPays]: "每位付款金額",
	[tokens.ops.locations.byItems]: "按商品",
	[tokens.ops.locations.selectItemsTosplitIntoNewOrders]: "選擇需要分單的商品",
	[tokens.ops.locations.order]: "訂單",
	[tokens.ops.locations.unpaid]: "未付款",
	[tokens.ops.locations.splitIntoNewOrder]: "分單",
	[tokens.ops.tills.createItem]: "新增商品",
	[tokens.ops.tills.itemType]: "商品類型",
	[tokens.ops.tills.paymentMode]: "付款方式",
	[tokens.ops.tills.changeToTab]: "之後收費",
	[tokens.ops.tills.payNow]: "立即付款",
	[tokens.ops.tills.paid]: "已付款",
	[tokens.ops.tills.clear]: "清除",
	[tokens.ops.tills.options]: "附加選項",
	[tokens.ops.tills.listedPrice]: "標價",
	[tokens.ops.tills.itemComments]: "商品備註",
	[tokens.ops.tills.cancel]: "取消",
	[tokens.ops.tills.save]: "保全",
	[tokens.ops.tills.customer]: "客戶",
	[tokens.ops.tills.kitchenMessage]: "發送",
	[tokens.ops.tills.lowestDenominator]: "最低零錢",
	[tokens.ops.tills.addHeadCount]: "加人數",
	[tokens.ops.tills.headCount]: "人數",
	[tokens.ops.tills.selectPaymentType]: "選擇付款方式",
	[tokens.ops.tills.addTips]: "加小費",
	[tokens.ops.tills.paymentTypeUsed]: "付款方式",
	[tokens.ops.tills.changeCalculator]: "零錢計算機",
	[tokens.ops.tills.totalRecieved]: "總收金額",
	[tokens.ops.tills.totalCashPayment]: "總共現金支付",
	[tokens.ops.tills.change]: "零錢",
	[tokens.ops.tills.locationIsRequired]: "必須選擇下單位置",
	[tokens.ops.tills.optionGroups]: "附加選項組",
	[tokens.ops.tills.maximumSelect]: "最高限額數量",
	[tokens.ops.tills.required]: "需要選擇",
	[tokens.ops.tills.nonRequired]: "可選擇",
	[tokens.ops.tills.grandTotal]: "總數",
	[tokens.ops.tills.courseSeperator]: "分隔線",
	[tokens.ops.tills.showMore]: "顯示全部付款方式",
	[tokens.ops.tills.showLess]: "隱藏一些付款方式",
	[tokens.ops.tills.cancelledThisItem]: "取消商品",
	[tokens.ops.tills.cancelledThisItemDesc]: "取消商品的此操作無法還原",
	[tokens.ops.tills.tips]: "小費",
	[tokens.ops.tills.tipModelTitleBeforeAmount]: "付款金額高過總數。需要加",
	[tokens.ops.tills.tipModelTitleAfterAmount]: "到小費?",
	[tokens.ops.tills.headcountRequiredBeforeTakePayment]: "必須輸入人數資料",
	[tokens.ops.tills.invalidPaymentAmount]: "付款金額總數金額不符",
	[tokens.ops.tills.selectTip]: "小費方式",
	[tokens.reports.nav.shift]: "工作時段",
	[tokens.reports.nav.from]: "從",
	[tokens.reports.nav.to]: "到",
	[tokens.reports.nav.generate]: "啟動",
	[tokens.reports.nav.export]: "導出",
	[tokens.reports.nav.summary]: "銷售總結",
	[tokens.reports.nav.customs]: "Customs",
	[tokens.reports.nav.receipts]: "付款收據",
	[tokens.reports.nav.categories]: "品類銷售數據",
	[tokens.reports.nav.items]: "商品銷售數據",
	[tokens.reports.nav.options]: "Options",
	[tokens.reports.nav.locations]: "下單位置數據",
	[tokens.reports.nav.staffs]: "員工銷售數據",
	[tokens.reports.nav.details]: "銷售細節",
	[tokens.reports.nav.inventory]: "庫存數據",
	[tokens.menus.menuManagement]: "選單管理",
	[tokens.menus.menus]: "選單",
	[tokens.menus.createNewMenu]: "新增選單",
	[tokens.menus.updateMenu]: "更新選單",
	[tokens.menus.logo]: "選單圖片",
	[tokens.menus.name]: "商品名稱",
	[tokens.menus.description]: "選單描述",
	[tokens.menus.visible]: "顯示",
	[tokens.menus.view]: "進入",
	[tokens.menus.edit]: "更改",
	[tokens.menus.viewItems]: "參考商品",
	[tokens.menus.viewOptions]: "參考附加選項",
	[tokens.menus.copyExistingCategory]: "複製現有品類",
	[tokens.menus.copyThisCategory]: "複製商品品類",
	[tokens.menus.createNewCategory]: "新增商品品類",
	[tokens.menus.copyFromExistingItem]: "複製現有商品",
	[tokens.menus.copyThisItem]: "Copy this item",
	[tokens.menus.createNewItem]: "新增商品",
	[tokens.menus.selectACategoryFirstBeforeCloningItem]: "先選品類才能複製商品",
	[tokens.menus.categoryName]: "商品品類名稱",
	[tokens.menus.price]: "商品價格",
	[tokens.menus.type]: "商品類型",
	[tokens.menus.category]: "商品品類",
	[tokens.menus.pos]: "POS",
	[tokens.menus.digitalMenu]: "電子Menu",
	[tokens.menus.categoryTitle]: "商品品類名稱",
	[tokens.menus.categoryIsARequiredField]: "必須輸入資料欄",
	[tokens.menus.titleIsARequiredField]: "必須輸入資料欄",
	[tokens.menus.displayInDigitalMenu]: "顯示在電子menu",
	[tokens.menus.displayInTill]: "顯示在POS",
	[tokens.menus.printer]: "打印機",
	[tokens.menus.printerTagIsARequiredField]: "必須輸入資料欄",
	[tokens.menus.typeIsARequiredField]: "必須輸入資料欄",
	[tokens.menus.title]: "商品名稱",
	[tokens.menus.originalPrice]: "原價",
	[tokens.menus.prepTime]: "準備時間",
	[tokens.menus.extraQuantity]: "附加數量",
	[tokens.menus.listedPrice]: "商品標價",
	[tokens.menus.addOption]: "添加附加選項",
	[tokens.menus.copyOption]: "複製選項",
	[tokens.menus.copyThisOption]: "複製選項",
	[tokens.menus.copyOptionToItem]: "複製附加選項到商品",
	[tokens.menus.addOptionGroup]: "添加附加選項組",
	[tokens.menus.copyOptionGroup]: "複製選項組",
	[tokens.menus.addOptionGroupToItem]: "添加複製附加選項組到商品",
	[tokens.menus.copyOptionGroupToItem]: "複製附加選項組到商品",
	[tokens.menus.newOptionsGroup]: "新增附加選項組",
	[tokens.menus.copyThisOptionGroup]: "複製選項組",
	[tokens.menus.copyFromExistingOptionGroup]: "複製從現有附加選項組",
	[tokens.menus.copyFromExistingOptions]: "複製從現有附加選項",
	[tokens.menus.optionGroupName]: "選項組名稱",
	[tokens.menus.updateOptionGroup]: "更新選項組",
	[tokens.menus.addFromExistingOption]: "添加附加選項",
	[tokens.menus.removeThisOption]: "移除附加選項",
	[tokens.menus.optionTitle]: "附加選項名稱",
	[tokens.menus.optionName]: "選項名稱",
	[tokens.menus.optionPrice]: "選項原價",
	[tokens.menus.duplicateOptionName]: "重複選項名稱",
	[tokens.menus.duplicateOptionGroupName]: "重複選項組名稱",
	[tokens.menus.youAreAboutToDeleteThisOption]: "刪除附加選項",
	[tokens.menus.updateItem]: "更新商品",
	[tokens.menus.itemOptions]: "商品的附加選項",
	[tokens.menus.itemOptionGroups]: "商品的附加選項組",
	[tokens.menus.manageItemRecipe]: "商品配方管理",
	[tokens.menus.selectOptions]: "選擇附加選項",
	[tokens.menus.selectOptionGroups]: "選擇附加選項組",
	[tokens.menus.updateRecipeToMenuOption]: "更新附加選項",
	[tokens.menus.updateItemOptionGroup]: "更新商品的附加選項組",
	[tokens.menus.requiredOption]: "必須選擇這個附加選項組",
	[tokens.menus.minimumSelect]: "最小限額數量",
	[tokens.menus.maximumSelect]: "最高限額數量",
	[tokens.menus.addThisOption]: "加入",
	[tokens.menus.newOption]: "新增附加選項",
	[tokens.menus.createNewOptionGroup]: "新增附加選項組",
	[tokens.menus.createNewOption]: "新增附加選項",
	[tokens.menus.updateOption]: "更新附加選項",
	[tokens.menus.optionGroupTitle]: "附加選項組名稱",
	[tokens.menus.vat]: "銷售稅",
	[tokens.menus.serviceCharge]: "服務費金額",
	[tokens.menus.autoSelectOption]: "自動加入訂單",
	[tokens.menus.minimumRequiredPrice]: "最低入單價錢",
	[tokens.menus.itemId]: "商品編號",
	[tokens.menus.optionId]: "選項編號",
	[tokens.menus.hideFromReceipt]: "在帳單隱藏",
	[tokens.menus.isHideFromReceipt]: "在帳單隱藏",
	[tokens.menus.action]: "操作",
	[tokens.reports.summary.salesSummary]: "銷售總結",
	[tokens.reports.summary.tableView]: "表哥模式",
	[tokens.reports.summary.chartView]: "圖表模式",
	[tokens.reports.summary.subtotalPrice]: "小計金額",
	[tokens.reports.summary.discount]: "折扣金額",
	[tokens.reports.summary.offers]: "優惠金額",
	[tokens.reports.summary.netSales]: "淨銷售額",
	[tokens.reports.summary.serviceCharge]: "服務費金額",
	[tokens.reports.summary.vat]: "銷售稅金額",
	[tokens.reports.summary.grossSales]: "總銷售金額",
	[tokens.reports.summary.cancelled]: "取消金額",
	[tokens.reports.summary.roundingAmount]: "取整金額 ",
	[tokens.reports.summary.paymentsReceived]: "已收金額",
	[tokens.reports.summary.salesByDays]: "每日銷售額",
	[tokens.reports.summary.columns]: "直欄",
	[tokens.reports.summary.filters]: "篩選",
	[tokens.reports.summary.density]: "密度",
	[tokens.reports.summary.findColumn]: "尋找直欄",
	[tokens.reports.summary.columnTitle]: "直欄名稱",
	[tokens.reports.summary.hideAll]: "隱藏全部",
	[tokens.reports.summary.showAll]: "顯示全部",
	[tokens.reports.summary.operator]: "選擇",
	[tokens.reports.summary.value]: "數據",
	[tokens.reports.summary.is]: "是",
	[tokens.reports.summary.isNot]: "不是",
	[tokens.reports.summary.isAfter]: "是之後",
	[tokens.reports.summary.isOnOrAfter]: "在活之後",
	[tokens.reports.summary.isBefore]: "是直前",
	[tokens.reports.summary.isOnOrBefore]: "在活直前",
	[tokens.reports.summary.clear]: "清楚",
	[tokens.reports.summary.today]: "今天",
	[tokens.reports.summary.equals]: "等於",
	[tokens.reports.summary.notEqual]: "不等於",
	[tokens.reports.summary.greaterThan]: "大於",
	[tokens.reports.summary.greaterThanOrEqual]: "大於或等於",
	[tokens.reports.summary.lessThan]: "小於",
	[tokens.reports.summary.lessThanOrEqual]: "小於或等於",
	[tokens.reports.summary.isAnyOf]: "任何選擇",
	[tokens.reports.summary.date]: "日期",
	[tokens.reports.summary.offerAmount]: "優惠金額",
	[tokens.reports.summary.discountAmount]: "折扣金額",
	[tokens.reports.summary.adjustment]: "調整金額",
	[tokens.reports.summary.cancelledAmount]: "取消金額",
	[tokens.reports.summary.avgOrderAmount]: "平津單價金額",
	[tokens.reports.summary.receiptsCount]: "付款收據數量",
	[tokens.reports.summary.averageReceiptAmount]: "平均收據金額",
	[tokens.reports.summary.compact]: "壓縮",
	[tokens.reports.summary.standard]: "標準",
	[tokens.reports.summary.comfortable]: "寬鬆",
	[tokens.reports.summary.downloadAsCsv]: "下載CSV",
	[tokens.reports.summary.print]: "打印",
	[tokens.reports.summary.beforeYouProceed]: "在你繼續之前",
	[tokens.reports.summary.processTakesAwhileMessage]:
		"此過程可需要一點時間。系統需要收集並計算數據。如設定的日期越長系統需要更多時間。",
	[tokens.reports.summary.printerForReportIsAvailable]: "報告的打印機可使用",
	[tokens.reports.summary.printerForReportIsNotAvailable]: "五連上打印機",
	[tokens.reports.summary.printServiceIsOnlineMessage]:
		"保持打印機程式在啟動狀態",
	[tokens.reports.summary.setupHere]: "Set up here!",
	[tokens.reports.summary.printServiceIsOfflineMessage]:
		"打印程式無開通。請啟動打印程式。",
	[tokens.reports.summary.selectTheTypeOfReportsForExport]:
		"選擇你要導出的報告",
	[tokens.reports.summary.salesSummaryReports]: "銷售統計報告",
	[tokens.reports.summary.itemsSalesReports]: "商品統計報告",
	[tokens.reports.summary.printOnlyItemsWithSales]: "直打印有銷售的項目",
	[tokens.reports.summary.paymentTypes]: "付款方式",
	[tokens.reports.summary.locationGroupTags]: "下單訂位類型",
	[tokens.reports.summary.downloadSoftCopy]: "下載電子檔",
	[tokens.reports.custom.newCustomReportCard]: "新增自訂報告",
	[tokens.reports.custom.updateCustomReportCard]: "更新自訂報告",
	[tokens.reports.custom.cardHeader]: "報告抬頭",
	[tokens.reports.custom.chartType]: "圖表類型",
	[tokens.reports.custom.configuration]: "設定",
	[tokens.reports.custom.label]: "Label",
	[tokens.reports.custom.salesValue]: "銷售類型",
	[tokens.reports.custom.dataType]: "數據類型",
	[tokens.reports.custom.dataPoint]: "數據點",
	[tokens.reports.custom.addDataPoint]: "添加數據點",
	[tokens.reports.custom.bulkAddDataPoint]: "批量加入數據點",
	[tokens.reports.custom.youAreAboutToDeleteThisCustomReport]:
		"You are about to delete this custom report",
	[tokens.reports.custom.id]: "ID",
	[tokens.reports.custom.title]: "品類名稱",
	[tokens.reports.custom.count]: "Count",
	[tokens.reports.custom.unitPrice]: "商品價格",
	[tokens.reports.custom.subtotal]: "小計",
	[tokens.reports.custom.discount]: "折扣",
	[tokens.reports.custom.offer]: "優惠",
	[tokens.reports.custom.netSales]: "淨銷售額",
	[tokens.reports.custom.serviceCharge]: "服務費金額",
	[tokens.reports.custom.vat]: "銷售稅金額",
	[tokens.reports.custom.grossSales]: "總銷售金額",
	[tokens.reports.custom.cancelledValue]: "取消金額",
	[tokens.reports.receipts.avgPaymentPerReceipt]: "收據平均支付金額",
	[tokens.reports.receipts.receiptCount]: "收據數量",
	[tokens.reports.receipts.nonVoidReceipt]: "有效收據",
	[tokens.reports.receipts.voidReceipt]: "取消數據",
	[tokens.reports.receipts.byReceiptTypes]: "收據類型",
	[tokens.reports.receipts.internalReceipts]: "內部收據",
	[tokens.reports.receipts.externalReceipts]: "客戶數據",
	[tokens.reports.receipts.allBlank]: "全無內容",
	[tokens.reports.receipts.addFilter]: "增加篩選",
	[tokens.reports.receipts.year]: "年",
	[tokens.reports.receipts.month]: "月",
	[tokens.reports.receipts.date]: "日",
	[tokens.reports.receipts.hour]: "鐘",
	[tokens.reports.receipts.minute]: "分",
	[tokens.reports.receipts.receipt]: "數據編號",
	[tokens.reports.receipts.orders]: "訂單編號",
	[tokens.reports.receipts.locationTypes]: "下單訂位類型",
	[tokens.reports.receipts.locationName]: "下單訂位名稱",
	[tokens.reports.receipts.staff]: "員工",
	[tokens.reports.receipts.status]: "訂單狀態",
	[tokens.reports.receipts.subtotalPrice]: "小計金額",
	[tokens.reports.receipts.offerName]: "優惠名稱",
	[tokens.reports.receipts.offerAmount]: "優惠金額",
	[tokens.reports.receipts.discountAmount]: "折扣金額",
	[tokens.reports.receipts.netSales]: "淨銷售額",
	[tokens.reports.receipts.vat]: "銷售稅金額",
	[tokens.reports.receipts.serviceCharge]: "服務費金額",
	[tokens.reports.receipts.grossAmount]: "總銷售金額",
	[tokens.reports.receipts.roundingAmt]: "取整金額",
	[tokens.reports.receipts.adjustmentAmt]: "調整金額",
	[tokens.reports.receipts.cancelledAmount]: "取消金額",
	[tokens.reports.receipts.paymentTypes]: "付款方式",
	[tokens.reports.receipts.paymentsReceived]: "已收金額",
	[tokens.reports.receipts.customers]: "客戶",
	[tokens.reports.receipts.headcount]: "人數",
	[tokens.reports.receipts.tips]: "小費",
	[tokens.reports.receipts.shift]: "工作時段",
	[tokens.reports.receipts.paywayrefs]: "電子支付編號",
	[tokens.reports.receipts.kpayPaymentTypes]: "KPay付款方式",
	[tokens.reports.categories.total]: "總共金額",
	[tokens.reports.categories.top10ByRevenue]: "按銷售金額排名前十位",
	[tokens.reports.categories.top10BySoldItems]: "按銷售量排名前十位",
	[tokens.reports.categories.categoryName]: "商品品類名稱",
	[tokens.reports.categories.soldItems]: "銷售數量",
	[tokens.reports.categories.revenue]: "銷售金額",
	[tokens.reports.categories.title]: "品類名稱",
	[tokens.reports.categories.subtotal]: "小計",
	[tokens.reports.categories.discount]: "折扣",
	[tokens.reports.categories.offer]: "優惠",
	[tokens.reports.categories.netSales]: "淨銷售額",
	[tokens.reports.categories.serviceCharge]: "服務費金額",
	[tokens.reports.categories.vat]: "銷售稅金額",
	[tokens.reports.categories.grossSales]: "總銷售金額",
	[tokens.reports.categories.cancelledValue]: "取消金額",
	[tokens.reports.categories.sort]: "排列",
	[tokens.reports.categories.unsort]: "取消排列",
	[tokens.reports.categories.sortByAsc]: "按升續排列",
	[tokens.reports.categories.sortByDesc]: "按降序排列",
	[tokens.reports.categories.filters]: "篩選",
	[tokens.reports.categories.hideAll]: "隱藏全部",
	[tokens.reports.categories.showColumns]: "顯示直欄",
	[tokens.reports.categories.pinToLeft]: "固定到左側",
	[tokens.reports.categories.pinToRight]: "固定到右側",
	[tokens.reports.items.revenueByItemTypes]: "商品類型銷售金額",
	[tokens.reports.items.soldItemsByItemTypes]: "商品類型銷售數量",
	[tokens.reports.items.itemType]: "商品類型",
	[tokens.reports.items.revenue]: "銷售金額",
	[tokens.reports.items.itemTitle]: "商品名稱",
	[tokens.reports.items.optionTitle]: "Option Title",
	[tokens.reports.items.soldItem]: "Sold Item",
	[tokens.reports.items.categoryTitle]: "品類名稱",
	[tokens.reports.items.subtotal]: "小計金額",
	[tokens.reports.items.netAmount]: "淨銷售額",
	[tokens.reports.items.total]: "總共金額",
	[tokens.reports.items.discount]: "折扣",
	[tokens.reports.items.offer]: "優惠",
	[tokens.reports.items.serviceCharge]: "服務費金額",
	[tokens.reports.items.vat]: "銷售稅金額",
	[tokens.reports.items.grossSales]: "總銷售金額",
	[tokens.reports.items.cancelledValue]: "取消金額",
	[tokens.reports.items.itemId]: "商品編號",
	[tokens.reports.items.top10ByRevenue]: "按銷售金額排名前十位",
	[tokens.reports.items.top10BySoldItems]: "按銷售量排名前十位",
	[tokens.reports.options.revenue]: "Revenue",
	[tokens.reports.options.optionTitle]: "Option Title",
	[tokens.reports.options.optionTitle]: "Option Title",
	[tokens.reports.options.categoryTitle]: "Category Title",
	[tokens.reports.options.subtotal]: "Subtotal",
	[tokens.reports.options.netAmount]: "Net Amount",
	[tokens.reports.options.total]: "Total",
	[tokens.reports.options.soldOption]: "Sold Option",
	[tokens.reports.options.discount]: "Discount",
	[tokens.reports.options.offer]: "Offer",
	[tokens.reports.options.serviceCharge]: "Service Charge",
	[tokens.reports.options.vat]: "VAT",
	[tokens.reports.options.grossSales]: "Gross Sales",
	[tokens.reports.options.cancelledValue]: "Cancelled Value",
	[tokens.reports.options.optionId]: "選項編號",
	[tokens.reports.options.top10ByRevenue]: "按銷售金額排名前十位",
	[tokens.reports.options.top10BySoldItems]: "按銷售量排名前十位",
	[tokens.reports.locations.mostPopular]: "銷售數量",
	[tokens.reports.locations.topMoneyMaker]: "銷售金額",
	[tokens.reports.locations.receivedPaymentsAll]: "已收金額(全部）",
	[tokens.reports.locations.salesByLocationGroupAll]: "下單訂位銷售分析",
	[tokens.reports.locations.locationTypes]: "下單訂位類型",
	[tokens.reports.locations.paymentTypes]: "付款方式",
	[tokens.reports.locations.showingAllLocations]: "顯示全部下單位置",
	[tokens.reports.locations.onlyXLocations]: '"x" 下單位置',
	[tokens.reports.locations.locationNames]: "下單訂位名稱",
	[tokens.reports.locations.ordersCount]: "訂單數量",
	[tokens.reports.locations.receiptCount]: "收據數量",
	[tokens.reports.locations.headcount]: "人數",
	[tokens.reports.locations.receiptsAmount]: "收據金額",
	[tokens.reports.locations.subtotalPrice]: "小計金額",
	[tokens.reports.locations.offerAmount]: "優惠金額",
	[tokens.reports.locations.discountAmount]: "折扣金額",
	[tokens.reports.locations.netSales]: "淨銷售額",
	[tokens.reports.locations.vatAmount]: "銷售稅金額",
	[tokens.reports.locations.serviceChargeAmount]: "服務費金額",
	[tokens.reports.locations.grossAmount]: "總銷售金額",
	[tokens.reports.locations.roundingAmt]: "取整金額 ",
	[tokens.reports.locations.adjustmentAmount]: "調整金額",
	[tokens.reports.locations.cancelledAmount]: "取消金額",
	[tokens.reports.locations.byPaymentTypes]: "付款方式",
	[tokens.reports.staffs.topPerformerByOrdersCount]: "最佳員工按訂單數量",
	[tokens.reports.staffs.ordersCount]: "訂單數量",
	[tokens.reports.staffs.ordersGrossSales]: "訂單總銷售金額",
	[tokens.reports.staffs.topPerformerByOrdersGrossSales]: "最佳員工按訂單金額",
	[tokens.reports.staffs.staffName]: "員工名稱",
	[tokens.reports.details.grossAmount]: "總銷售金額",
	[tokens.reports.details.qr]: "QR",
	[tokens.reports.details.till]: "收銀台",
	[tokens.reports.details.averageOrderAmount]: "平均訂單金額",
	[tokens.reports.details.ordersCount]: "訂單數量",
	[tokens.reports.details.year]: "年",
	[tokens.reports.details.month]: "月",
	[tokens.reports.details.date]: "日",
	[tokens.reports.details.hour]: "鐘",
	[tokens.reports.details.minute]: "分",
	[tokens.reports.details.receipt]: "收據編號",
	[tokens.reports.details.orders]: "訂單編號",
	[tokens.reports.details.locationTypes]: "下單訂位類型",
	[tokens.reports.details.locationName]: "下單訂位名稱",
	[tokens.reports.details.orderThrough]: "下單從",
	[tokens.reports.details.staffName]: "員工名稱",
	[tokens.reports.details.category]: "品類名稱",
	[tokens.reports.details.itemType]: "商品類型",
	[tokens.reports.details.item]: "商品名稱",
	[tokens.reports.details.status]: "訂單狀態",
	[tokens.reports.details.isPaid]: "已支付",
	[tokens.reports.details.isCancelled]: "已取消",
	[tokens.reports.details.qty]: "數量",
	[tokens.reports.details.unitPrice]: "商品價格",
	[tokens.reports.details.subtotalPrice]: "小計金額",
	[tokens.reports.details.offerName]: "優惠名稱",
	[tokens.reports.details.offerAmount]: "優惠金額",
	[tokens.reports.details.discountAmount]: "折扣金額",
	[tokens.reports.details.netSales]: "淨銷售額",
	[tokens.reports.details.vatIncluded]: "銷售稅金額（*包括）",
	[tokens.reports.details.scIncluded]: "Service Charge（*包括）",
	[tokens.reports.details.adjustmentAmount]: "調整金額",
	[tokens.reports.details.cancelledAmount]: "取消金額",
	[tokens.reports.details.cancelledReason]: "取消原櫻",
	[tokens.reports.details.finalPaymentType]: "付款方式",
	[tokens.reports.details.customers]: "客戶",
	[tokens.reports.details.shift]: "工作時段",
	[tokens.reports.details.paywayRef]: "電子支付編號",
	[tokens.reports.details.itemId]: "商品編號",
	[tokens.reports.inventory.liveData]: "Live Data",
	[tokens.reports.inventory.historicalData]: "Historical Data",
	[tokens.reports.inventory.totalInventoryBalanceValue]: "庫存總額",
	[tokens.reports.inventory.top10MostUtilisedRawMaterialsBalanceValue]:
		"前十名使用最高原材料餘額金額",
	[tokens.reports.inventory.top10LeastUtilisedRawMaterialsBalanceValue]:
		"前十名使用最低原材料餘額金額",
	[tokens.reports.inventory.top10MostUtilised]: "前十名使用最高的原材料",
	[tokens.reports.inventory.top10LeastUtilised]: "前十名使用最低的原材料",
	[tokens.reports.inventory.name]: "原材料名稱",
	[tokens.reports.inventory.balanceQty]: "庫存數量",
	[tokens.reports.inventory.balanceValue]: "庫存金額",
	[tokens.reports.inventory.rawMaterialName]: "原材料名稱",
	[tokens.reports.inventory.rawMaterialCode]: "雲材料編號",
	[tokens.reports.inventory.receivedUom]: "收貨單位",
	[tokens.reports.inventory.totalReceivedQty]: "總收數量",
	[tokens.reports.inventory.totalConsumedQty]: "總銷數量",
	[tokens.reports.inventory.totalWastageQty]: "總損耗數量",
	[tokens.reports.inventory.totalDefectQty]: "總損壞數量",
	[tokens.reports.inventory.totalExpiredQty]: "總過期數量",
	[tokens.reports.inventory.totalAdjustmentQty]: "總調整金額",
	[tokens.reports.inventory.balanceQuantity]: "庫存數量",
	[tokens.reports.inventory.avgCostPerUom]: "單位平均價",
	[tokens.reports.inventory.balanceValue]: "庫存金額",
	[tokens.reports.inventory.supplierName]: "供應商名稱",
	[tokens.reports.inventory.storageLocation]: "儲存位置",
	[tokens.receipts.landing.dates]: "日期",
	[tokens.receipts.landing.ordersUseForMultiples]: "查找訂單編號",
	[tokens.receipts.landing.receiptsUseForMultiples]: "查找收據編號",
	[tokens.receipts.landing.receipt]: "收據編號",
	[tokens.receipts.landing.createdAt]: "創造時間",
	[tokens.receipts.landing.location]: "下單位置",
	[tokens.receipts.landing.paymentType]: "付款方式",
	[tokens.receipts.landing.description]: "具體內容",
	[tokens.receipts.landing.actions]: "操作",
	[tokens.receipts.landing.reprint]: "重複打印",
	[tokens.receipts.landing.voidReceipt]: "撤銷收據",
	[tokens.receipts.expandReceiptDetails.receipt]: "收據編號",
	[tokens.receipts.expandReceiptDetails.ordersNumbers]: "訂單編號",
	[tokens.receipts.expandReceiptDetails.OfPeople]: "人數",
	[tokens.receipts.expandReceiptDetails.location]: "下單位置",
	[tokens.receipts.expandReceiptDetails.printedDate]: "打印時間",
	[tokens.receipts.expandReceiptDetails.orderedDate]: "訂單時間",
	[tokens.receipts.expandReceiptDetails.subtotal]: "小計",
	[tokens.receipts.expandReceiptDetails.offer]: "優惠",
	[tokens.receipts.expandReceiptDetails.discount]: "折扣",
	[tokens.receipts.expandReceiptDetails.vat]: "銷售稅",
	[tokens.receipts.expandReceiptDetails.serviceCharge]: "服務費",
	[tokens.receipts.expandReceiptDetails.adjustmentValue]: "調整金額",
	[tokens.receipts.expandReceiptDetails.grandTotal]: "總數",
	[tokens.receipts.expandReceiptDetails.roundingDifference]: "取整金額 ",
	[tokens.receipts.expandReceiptDetails.paymentTypes]: "付款方式",
	[tokens.receipts.expandReceiptDetails.cashReceived]: "已收現金",
	[tokens.receipts.expandReceiptDetails.cashChanges]: "現金找回",
	[tokens.receipts.expandReceiptDetails.cancelledAmount]: "取消金額",
	[tokens.receipts.expandReceiptDetails.paymentNotEqualToGrandTotal]:
		"付款金額跟總數有出別，需要調整",
	[tokens.receipts.expandReceiptDetails.cancelledItems]: "已取消的商品",
	[tokens.receipts.expandReceiptDetails.addPaymentType]: "添加付款方式",
	[tokens.orders.landing.dates]: "日期",
	[tokens.orders.landing.ordersUseForMultiples]: "查找訂單編號",
	[tokens.orders.landing.createdAt]: "創造時間",
	[tokens.orders.landing.paymentStatus]: "蟄伏狀態",
	[tokens.orders.landing.description]: "具體內容",
	[tokens.orders.landing.orderStatus]: "訂單狀態",
	[tokens.orders.landing.copyThisLogId]: "複製訂單ID",
	[tokens.orders.landing.placed]: "已下單",
	[tokens.orders.landing.preparing]: "準備中",
	[tokens.orders.landing.processed]: "已完成",
	[tokens.customers.landing.searchPlaceholder]:
		"按客戶姓名，電話，活編號來搜索",
	[tokens.customers.landing.newCustomer]: "新增客戶",
	[tokens.customers.landing.creatingNewCustomer]: "新增客戶",
	[tokens.customers.landing.formMessage]: "輸入以下的資料",
	[tokens.customers.landing.firstName]: "客戶名稱",
	[tokens.customers.landing.firstNameIsRequired]: "必須輸入客戶名稱",
	[tokens.customers.landing.lastName]: "客戶姓名",
	[tokens.customers.landing.lastNameIsRequired]: "必須輸入客戶姓名",
	[tokens.customers.landing.phoneNumber]: "電話號碼",
	[tokens.customers.landing.uniqueId]: "客戶編號",
	[tokens.customers.landing.socials]: "練習客戶的社交平台",
	[tokens.customers.landing.createdAt]: "創造時間",
	[tokens.customers.landing.fullName]: "客戶全名",
	[tokens.customers.customerDetails.customerInfo]: "客戶信息",
	[tokens.customers.customerDetails.orderHistory]: "Orders History",
	[tokens.customers.customerDetails.firstName]: "客戶名稱",
	[tokens.customers.customerDetails.lastName]: "客戶姓名",
	[tokens.customers.customerDetails.phoneNumber]: "電話號碼",
	[tokens.customers.customerDetails.uniqueId]: "客戶編號",
	[tokens.customers.customerDetails.dates]: "日期",
	[tokens.customers.customerDetails.items]: "商品",
	[tokens.customers.customerDetails.socials]: "練習客戶的社交平台",
	[tokens.customers.customerDetails.save]: "保存",
	[tokens.customers.customerDetails.salesSummary]: "銷售總結",
	[tokens.customers.customerDetails.grossAmount]: "總銷售金額",
	[tokens.customers.customerDetails.ordersCount]: "訂單數量",
	[tokens.customers.customerDetails.discount]: "折扣",
	[tokens.customers.customerDetails.columns]: "直欄",
	[tokens.customers.customerDetails.title]: "商品名稱",
	[tokens.customers.customerDetails.soldCount]: "銷售數量",
	[tokens.customers.customerDetails.hideAll]: "隱藏全部",
	[tokens.customers.customerDetails.showAll]: "顯示全部",
	[tokens.customers.customerDetails.filters]: "篩選",
	[tokens.customers.customerDetails.operator]: "選擇",
	[tokens.customers.customerDetails.value]: "數據",
	[tokens.customers.customerDetails.contains]: "包含",
	[tokens.customers.customerDetails.equals]: "等於",
	[tokens.customers.customerDetails.startsWith]: "開頭於",
	[tokens.customers.customerDetails.endsWith]: "尾頭於",
	[tokens.customers.customerDetails.isEmpty]: "空白",
	[tokens.customers.customerDetails.isNotEmpty]: "含數據",
	[tokens.customers.customerDetails.isAnyOf]: "任何選擇",
	[tokens.customers.customerDetails.density]: "密度",
	[tokens.customers.customerDetails.compact]: "壓縮",
	[tokens.customers.customerDetails.standard]: "標準",
	[tokens.customers.customerDetails.comfortable]: "寬鬆",
	[tokens.customers.customerDetails.export]: "導出",
	[tokens.customers.customerDetails.downloadAsCsv]: "下載CSV",
	[tokens.customers.customerDetails.print]: "打印",
	[tokens.customers.customerDetails.sortByAsc]: "按升續排列",
	[tokens.customers.customerDetails.sortByDesc]: "按降序排列",
	[tokens.customers.customerDetails.showColumns]: "顯示直欄",
	[tokens.customers.customerDetails.pinToLeft]: "固定到左側",
	[tokens.customers.customerDetails.pinToRight]: "固定到右側",
	[tokens.customers.customerDetails.noResultsFound]: "未找到結果",
	[tokens.customers.customerDetails.rowsPerPage]: "每頁的列數",
	[tokens.customers.customerDetails.createdAt]: "創造時間",
	[tokens.customers.customerDetails.paymentStatus]: "蟄伏狀態",
	[tokens.customers.customerDetails.description]: "具體內容",
	[tokens.customers.customerDetails.orderStatus]: "訂單狀態",
	[tokens.offers.landing.title]: "優惠名稱",
	[tokens.offers.landing.newOffer]: "新增優惠",
	[tokens.offers.landing.createdAt]: "創造時間",
	[tokens.offers.landing.summary]: "具體內容",
	[tokens.offers.landing.creatingNewOffer]: "新增優惠",
	[tokens.offers.landing.formMessage]: "輸入以下新優惠的資料",
	[tokens.offers.landing.offerTitle]: "優惠名稱",
	[tokens.offers.landing.tileIsRequired]: "必須輸入優惠名稱",
	[tokens.offers.landing.applyHigherValue]: "使用最高的折扣",
	[tokens.offers.landing.applyHighestValueMessage]:
		"如果優惠有兩種折扣，系統會選擇最高的折扣",
	[tokens.offers.landing.applyLowestValueMessage]:
		"If an item is qualified for 2 or more criterias, apply the LOWEST value criteria",
	[tokens.offers.landing.addConfig]: "選擇優惠的使用方式",
	[tokens.offers.landing.byReceipts]: "使用在整張單",
	[tokens.offers.landing.useWithOtherConfigTypes]: "只能用這個使用方式",
	[tokens.offers.landing.byItems]: "使用在個別的商品",
	[tokens.offers.landing.byCategories]: "使用在個別的商品品類",
	[tokens.offers.landing.byItemTypes]: "使用在個別的商品類型",
	[tokens.offers.landing.discountByReceipts]: "使用在整張單",
	[tokens.offers.landing.discountByItems]: "優惠使用在個別商品",
	[tokens.offers.landing.applicableItems]: "選擇商品",
	[tokens.offers.landing.discountByCategories]: "優惠使用在個別商品品類",
	[tokens.offers.landing.applicableCategories]: "選擇商品品類",
	[tokens.offers.landing.discountValue]: "折扣率",
	[tokens.offers.landing.discountByItemTypes]: "Discount By Item Types",
	[tokens.offers.landing.applicableItemTypes]: "Applicable Types",
	[tokens.offers.offerDetails.offerInfo]: "優惠信息",
	[tokens.offers.offerDetails.offerName]: "優惠名稱",
	[tokens.offers.offerDetails.offerStatus]: "優惠狀態",
	[tokens.offers.offerDetails.active]: "使用中",
	[tokens.offers.offerDetails.configSummary]: "優惠使用資訊",
	[tokens.offers.offerDetails.offerHigherValueUponCollision]: "使用最高的折扣",
	[tokens.offers.offerDetails.offerLowerValueUponCollision]:
		"Offer lower value upon collision",
	[tokens.offers.offerDetails.editThisOffer]: "更改優惠",
	[tokens.offers.offerDetails.deactivate]: "停用",
	[tokens.offers.offerDetails.offerUsageSummary]: "優惠",
	[tokens.offers.offerDetails.ordersCount]: "訂單數量",
	[tokens.offers.offerDetails.usageAmount]: "使用率",
	[tokens.offers.offerDetails.ordersHistory]: "訂單記錄",
	[tokens.offers.offerDetails.dates]: "日期",
	[tokens.offers.offerDetails.createdAt]: "創造時間",
	[tokens.offers.offerDetails.paymentStatus]: "支付狀態",
	[tokens.offers.offerDetails.description]: "具體內容",
	[tokens.offers.offerDetails.orderStatus]: "訂單狀態",
	[tokens.offers.offerDetails.copyThisLogId]: "複製訂單編號",
	[tokens.offers.offerDetails.criterias]: "優惠設定",
	[tokens.offers.offerDetails.updatingOffer]: "更新優惠",
	[tokens.offers.offerDetails.formHeaderDesc]: "輸入以下新優惠的資料",
	[tokens.offers.offerDetails.youCanTUseThisWithOtherConfigTypes]:
		"只能用這個使用方式",
	[tokens.settings.nav.general]: "基本設定",
	[tokens.settings.nav.ordering]: "下單設定",
	[tokens.settings.nav.printing]: "打印設定",
	[tokens.settings.nav.customs]: "自定設定",
	[tokens.settings.nav.userPermissions]: "權限設定",
	[tokens.settings.nav.addOns]: "額外功能",
	[tokens.settings.general.name]: "公司名稱",
	[tokens.settings.general.description]: "公司描述",
	[tokens.settings.general.address]: "公司地址",
	[tokens.settings.general.vatTin]: "公司銷售稅編號",
	[tokens.settings.ordering.requireCustomerForDigitalMenuOrders]:
		"QR下單：必須輸入客戶資料",
	[tokens.settings.ordering.enableOrderCommentsForDigitalMenuOrders]:
		"QR下單：開通訂單備註",
	[tokens.settings.ordering.digitalMenuLabel]:
		"QR下單：開通選擇付款方式前需要員工確認訂單",
	[tokens.settings.ordering.noteLabel]: "QR下單：開通必須輸入訂單備註",
	[tokens.settings.ordering.noteInstructionLabel]: "輸入訂單備註的指示",
	[tokens.settings.ordering.inputTheNoteInstructions]: "輸入訂單備註的指示",
	[tokens.settings.ordering.orderNotificationDings]: "輸入聲音提示次數",
	[tokens.settings.ordering.orderNotificationDingsHelper]: "將小費歸入付款金額",
	[tokens.settings.ordering.addVat]: "開通銷售稅",
	[tokens.settings.ordering.howVatShouldBeCharged]: "選擇銷售稅的收取方式",
	[tokens.settings.ordering.separatelyAsLineItem]: "單獨列出",
	[tokens.settings.ordering.vatShowMessage]: "銷售稅會單獨顯示在帳單或收據",
	[tokens.settings.ordering.vatBuriedMessage]:
		"VAT will be attributed to all your items and options price, and your consumers will not see a VAT charges",
	[tokens.settings.ordering.addServiceCharge]: "開通服務費",
	[tokens.settings.ordering.howMuchForServiceCharge]: "輸入服務費(%)",
	[tokens.settings.ordering.serviceFeeNote]:
		"Note that the above amount will include 5% Served fee",
	[tokens.settings.ordering.howServiceShouldCharged]: "選擇服務費的收取方式",
	[tokens.settings.ordering.buriedInItemsAndOptionsPrice]: "包含在商品價格內",
	[tokens.settings.ordering.serviceChargeShowMessage]:
		"服務費會單獨顯示在帳單或收據",
	[tokens.settings.ordering.serviceChargeBuriedMessage]:
		"服務費會包含在商品價格裡，帳單或收據不會單獨顯示出來",
	[tokens.settings.ordering.opsPageFontSize]: "運作頁面字體大小 （像素）",
	[tokens.settings.ordering.opsPageFontsSizePx]: "字體大小 （像素）",
	[tokens.settings.printing.printServiceStatus]: "打印程式狀態",
	[tokens.settings.printing.printServiceStatusMessage]: "打印程式無連接",
	[tokens.settings.printing.printOrderChecklist]: "打印訂單清單",
	[tokens.settings.printing.printTicketByItem]: "打印为一张票一个商品",
	[tokens.settings.printing.printTicketByOption]: "打印為一張票一個選項",
	[tokens.settings.printing.isGroupingOptionOnPrintTicket]:
		"打印合併一樣的選項",
	[tokens.settings.printing.printContentPaddingPixels]: "打印內容邊距（像素）",
	[tokens.settings.printing.topPx]: "上（像素）",
	[tokens.settings.printing.rightPx]: "右（像素）",
	[tokens.settings.printing.bottomPx]: "下（像素）",
	[tokens.settings.printing.leftPx]: "左（像素）",
	[tokens.settings.printing.printFontSizesPixels]: "打印字體大小（像素）",
	[tokens.settings.printing.ticketChecklistMessagePx]: "廚房小票（像素）",
	[tokens.settings.printing.billReceiptPx]: "帳單，收據",
	[tokens.settings.printing.reportsPx]: "報告",
	[tokens.settings.printing.createNewStation]: "新增打印機工作站",
	[tokens.settings.printing.createNewWorkStation]: "新增打印機工作站",
	[tokens.settings.printing.updateWorkStation]: "更新打印機工作站",
	[tokens.settings.printing.whatShouldBeTheStationName]: "輸入打印機工作站名稱",
	[tokens.settings.printing.selectAPrinterForThisStation]: "選擇打印機",
	[tokens.settings.printing.itemTypesOfInterest]: "打印的商品類型",
	[tokens.settings.printing.printerTagsOfInterest]: "勾選一下打印機該打印的",
	[tokens.settings.printing.locationsOfInterest]: "輸入打印機該打印的下單位置",
	[tokens.settings.printing.cancel]: "取消",
	[tokens.settings.printing.save]: "保全",
	[tokens.settings.printing.stationName]: "打印機工作站",
	[tokens.settings.printing.usingPrinter]: "是用的打印機",
	[tokens.settings.printing.manage]: "操作",
	[tokens.settings.printing.kitchenMessage]: "通知廚房",
	[tokens.settings.printing.ticket]: "ticket",
	[tokens.settings.printing.receipt]: "收據編號",
	[tokens.settings.printing.checklist]: "清單",
	[tokens.settings.printing.report]: "報告",
	[tokens.settings.printing.cancelledOrderTicket]: "已取消的訂單",
	[tokens.settings.printing.delete]: "刪除",
	[tokens.settings.customs.customPrintingFields]: "自定打印欄位",
	[tokens.settings.customs.typeAndPressEnter]: "輸入後按Enter",
	[tokens.settings.customs.availableItemTypes]: "輸入商品類型",
	[tokens.settings.customs.customPaymentTypes]: "輸入付款方式",
	[tokens.settings.customs.shifts]: "新增班次",
	[tokens.settings.customs.locationGroupTags]: "輸入訂單位置類型",
	[tokens.settings.customs.printerTagsOfInterest]: "輸入打印標籤",
	[tokens.settings.customs.orderCancelReasons]: "輸入訂單取消原櫻",
	[tokens.settings.customs.presetMessages]: "先定通知",
	[tokens.settings.customs.receiptFormat]: "收據格式",
	[tokens.settings.customs.receiptCurrentCounter]: "收據序號",
	[tokens.settings.customs.semicolonIsAReservedCharacter]: "不能用分號（；）",
	[tokens.settings.customs.currenciesConfiguration]: "貨幣管理",
	[tokens.settings.customs.currencyCode]: "貨幣編號",
	[tokens.settings.customs.rateToPrimaryCurrency]: "對應著貨幣的匯率",
	[tokens.settings.customs.primary]: "住貨幣",
	[tokens.settings.customs.secondary]: "次貨幣",
	[tokens.settings.customs.deactivate]: "停用",
	[tokens.settings.customs.addCurrency]: "添加貨幣",
	[tokens.settings.customs.createCurrencyConfiguration]: "添加貨幣",
	[tokens.settings.customs.rateComparedToPrimaryCurrency]: "對應著貨幣的匯率",
	[tokens.settings.customs.defaultOpsPage]: "預設頁面",
	[tokens.settings.customs.printerTags]: "打印標籤",
	[tokens.settings.userPermissions.searchUsername]: "搜尋使用者",
	[tokens.settings.userPermissions.reFetchUsers]: "搜尋",
	[tokens.settings.userPermissions.ORDER_MANAGEMENT_FEATURE]: "訂單管理",
	[tokens.settings.userPermissions.VIEW_ORDER]: "查看訂單",
	[tokens.settings.userPermissions.CREATE_ORDER]: "新增訂單",
	[tokens.settings.userPermissions.EDIT_ORDER_BEFORE_BILL_PRINTED]:
		"打印帳單前可更改訂單",
	[tokens.settings.userPermissions.EDIT_ORDER_AFTER_BILL_PRINTED]:
		"打印帳單後可更改訂單",
	[tokens.settings.userPermissions.EDIT_ORDER_BEFORE_PAYMENT]:
		"支付前可更改訂單",
	[tokens.settings.userPermissions.EDIT_ORDER_AFTER_PAYMENT]:
		"支付後可更改訂單",
	[tokens.settings.userPermissions.CANCEL_ORDER_BEFORE_BILL_PRINTED]:
		"打印帳單前可取消訂單",
	[tokens.settings.userPermissions.CANCEL_ORDER_AFTER_BILL_PRINTED]:
		"打印帳單後可取消訂單",
	[tokens.settings.userPermissions.CANCEL_ORDER_BEFORE_PAYMENT]:
		"支付前可取消訂單",
	[tokens.settings.userPermissions.CANCEL_ORDER_AFTER_PAYMENT]:
		"支付後可取消訂單",
	[tokens.settings.userPermissions.RECEIPT_MANAGEMENT]: "收據管理",
	[tokens.settings.userPermissions.CANCEL_RECEIPT]: "取消收據",
	[tokens.settings.userPermissions.USER_SETTINGS]: "用戶設定",
	[tokens.settings.userPermissions.OPEN_CASHDRAWER]: "Open Cashdrawer",
	[tokens.settings.userPermissions.AUTO_LOGOUT]: "自動登出",
	[tokens.settings.addOns.enableCustomReceiptFormat]: "Custom Receipt Format",
	[tokens.settings.addOns.consumerAppTheme]: "Consumer App Theme",
	[tokens.settings.addOns.shouldAggregateTipsIntoTotalGrossSales]:
		"將小費歸入付款金額",
	[tokens.settings.addOns.primaryColor]: "Primary Color",
	[tokens.settings.addOns.primaryTextColor]: "Primary Text Color",
	[tokens.settings.addOns.enableInventoryMangement]:
		"Enable inventory mangement",
	[tokens.settings.addOns.enablePurchaseManagement]:
		"Enable purchase management",
	[tokens.settings.addOns.enablePaymentRounding]: "Enable payment rounding",
	[tokens.settings.addOns.venueOnlyUseDigitalMenu]:
		"Venue only use digital menu",
	[tokens.settings.addOns.kpayLabel]:
		"Whether venue has KPay integration enabled",
	[tokens.settings.addOns.enableInternalReceipt]: "Enable internal receipt",
	[tokens.settings.addOns.enableLanguageLocalization]:
		"Enable Language Localization",
	[tokens.settings.addOns.requireHeadcountBeforePayment]:
		"付款時必須輸入人數資料",
	[tokens.settings.addOns.opsIntervalLabel]:
		"Operational Data Refectch INterval (minutes)",
	[tokens.settings.addOns.enableRoomMasterIntegration]:
		"Enable Room Master Integration",
	[tokens.settings.addOns.setUpRoomMasterPropertyId]:
		"Set up Room Master Property ID",
	[tokens.settings.addOns.enableCustomReport]: "自訂報告",
	[tokens.settings.addOns.yes]: "Yes",
	[tokens.settings.addOns.no]: "No",
	[tokens.printservice.staff]: "員工",
	[tokens.printservice.print]: "打印",
	[tokens.printservice.ordered]: "訂單日期",
	[tokens.printservice.orders]: "訂單數量",
	[tokens.printservice.qty]: "數量",
	[tokens.printservice.item]: "商品",
	[tokens.printservice.price]: "價格",
	[tokens.printservice.subtotal]: "小計",
	[tokens.printservice.vat]: "銷售稅",
	[tokens.printservice.serviceCharge]: "服務費",
	[tokens.printservice.grandTotal]: "總數",
	[tokens.printservice.receipt]: "收據編號",
	[tokens.printservice.paymentTypes]: "付款方式",
	[tokens.printservice.cashReceived]: "已收現金",
	[tokens.printservice.cashChanges]: "現金找回",
	[tokens.printservice.received]: "訂單日期",
	[tokens.global.bill]: "帳單",
	[tokens.global.location]: "下單位置",
	[tokens.global.orders]: "訂單",
	[tokens.global.placed]: "已下單",
	[tokens.global.preparing]: "準備中",
	[tokens.global.processed]: "已完成",
	[tokens.global.proceed]: "進行",
	[tokens.global.unpaid]: "未付款",
	[tokens.global.paid]: "已付款",
	[tokens.global.pay]: "付款",
	[tokens.global.payNow]: "立即支付",
	[tokens.global.payLater]: "Pay Later",
	[tokens.global.move]: "移動",
	[tokens.global.of]: "準備中",
	[tokens.global.not]: "不允許",
	[tokens.global.all]: "已完成",
	[tokens.global.allowed]: "允許",
	[tokens.global.view]: "進入",
	[tokens.global.details]: "Details",
	[tokens.global.add]: "添加",
	[tokens.global.create]: "新增",
	[tokens.global.edit]: "更改",
	[tokens.global.update]: "更新",
	[tokens.global.save]: "未付款",
	[tokens.global.yes]: "確認",
	[tokens.global.no]: "取消",
	[tokens.global.delete]: "刪除",
	[tokens.global.send]: "發送",
	[tokens.global.undo]: "回復",
	[tokens.global.back]: "返回",
	[tokens.global.close]: "退出",
	[tokens.global.done]: "Done",
	[tokens.global.cancel]: "取消",
	[tokens.global.cancelled]: "訂單已取消",
	[tokens.global.subTotal]: "小計",
	[tokens.global.offer]: "優惠",
	[tokens.global.discount]: "折扣",
	[tokens.global.vat]: "銷售稅",
	[tokens.global.service]: "服務費",
	[tokens.global.adjustment]: "調整",
	[tokens.global.grandTotal]: "總數",
	[tokens.global.presetMessages]: "先定通知",
	[tokens.global.logout]: "登出",
	[tokens.global.manage]: "Manage",
	[tokens.global.copy]: "複製",
	[tokens.global.addThisOption]: "加入",
	[tokens.global.cancelReason]: "選擇取消理由",
	[tokens.global.cancelReasonIsRequired]: "必須輸入資料欄",
	[tokens.global.createAndReturnToTill]: "Create and return to till",
	[tokens.global.reset]: "重置",
	[tokens.global.newerAppVersionAvailable]: "系統已更新, 請刷新頁面",
	[tokens.global.reloadPage]: "刷新",
};
