import { useQuery } from "@tanstack/react-query";

import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetOperationalGeolocationsQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetOperationalGeolocations {
		getOperationalGeolocations {
			_id
			created_at
			updated_at
			type
			venue
			location
			locations_groups_tag
			shape {
				type
				center
				height
				width
				size
				radius
			}
		}
	}
`;

export type GetOperationalGeolocationsResults =
	GetOperationalGeolocationsQuery["getOperationalGeolocations"];

export const getOperationalGeolocationsKey = () => [
	"getOperationalGeolocations",
];

export const getOperationalGeolocationsQueryFn = () =>
	hubGqlClient
		.request(graphql(query))
		.then((res) => res.getOperationalGeolocations);

export const useGetOperationalGeolocations = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getOperationalGeolocationsKey(),
		queryFn: getOperationalGeolocationsQueryFn,
		initialData: [],
	});
};

export const useGetOperationalGeolocationsCache = () => {
	return useGetOperationalGeolocations(false);
};

export const getOperationalGeolocationsCache = () => {
	return (
		window.$queryClient.getQueryData<GetOperationalGeolocationsResults>(
			getOperationalGeolocationsKey(),
			{
				exact: true,
			},
		) ?? []
	);
};
