import { Client } from "graphql-ws";

import { SubscribeToVenueRefetchOperationalLocationsCommandSubscription } from "~/types/__generated/gql/graphql";
import {
	getOperationalGeolocationsKey,
	getOperationalGeolocationsQueryFn,
} from "../useGetOperationalGeolocations";
import {
	getOperationalLocationsKey,
	getOperationalLocationsQueryFn,
} from "../useGetOperationalLocations";

const query = /* GraphQL */ `
	subscription SubscribeToVenueRefetchOperationalLocationsCommand {
		subscribeToVenueRefetchOperationalLocationsCommand {
			id
		}
	}
`;

export const subscribeToVenueRefetchOperationalLocationsCommand = async (
	client: Client,
) => {
	const subscription =
		client.iterate<SubscribeToVenueRefetchOperationalLocationsCommandSubscription>(
			{
				query,
			},
		);
	for await (const result of subscription) {
		if (result.data) {
			getOperationalGeolocationsQueryFn()
				.then((res) =>
					window.$queryClient.setQueryData(
						getOperationalGeolocationsKey(),
						res,
					),
				)
				.catch(console.error);
			getOperationalLocationsQueryFn()
				.then((res) =>
					window.$queryClient.setQueryData(getOperationalLocationsKey(), res),
				)
				.catch(console.error);
		}
	}
};
