import { GraphQLClient, ClientError } from "graphql-request";
import { RequestMiddleware, Response } from "graphql-request/build/esm/types";
import { ClientOptions, createClient } from "graphql-ws";
import i18n from "i18next";

import { getState, setState } from "~/store";

import { browserStorage } from "./localforage";

export { getHubGqlWsClient, hubGqlClient };

const requestMiddleware: RequestMiddleware = async (request) => {
	const authHeaders = await getAuthHeaders();
	return { ...request, headers: { ...request.headers, ...authHeaders } };
};

const getErrorMessages = (response: Response<unknown> | ClientError) => {
	let messages = "";

	if (response instanceof ClientError) {
		messages =
			response.response.errors?.[0]?.message ?? "Something went wrong!";
	}

	return messages;
};

const responseMiddleware = (response: Response<unknown> | ClientError) => {
	const messages = getErrorMessages(response);
	if (messages) {
		console.log("=== GQL CLIENT ERROR ===", messages);
		setState({ error: messages });
	}
};

const hubGqlClient = new GraphQLClient(process.env.HUB_GQL_URL!, {
	requestMiddleware,
	// @ts-expect-error - responseMiddleware is not properly typed
	responseMiddleware,
});

const getAuthHeaders = async () => {
	const obj = {} as Record<string, string>;

	const { venueId, token: _token } = getState();
	const token = _token ?? (await browserStorage.token.get());

	if (token && obj.Authorization === undefined)
		obj.Authorization = `Bearer ${token}`;
	if (venueId) obj["x-served-venue-id"] = venueId;
	obj["x-served-staff-app-signature"] = new Date().getTime().toString();
	obj["x-served-laguage"] = i18n.language || "en";

	return obj;
};

const getHubGqlWsClient = () => {
	return createClient({
		url: process.env.HUB_GQL_WS_URL!,
		keepAlive: 5000,
		shouldRetry: () => true,
		retryAttempts: Infinity,
		retryWait: () => new Promise((resolve) => setTimeout(resolve, 2000)),
		connectionParams: getAuthHeaders,
		on: getWsClientLogger(),
	});
};

let timer: NodeJS.Timeout | null = null;

export const getWsClientLogger = (): ClientOptions["on"] => {
	return {
		connected: () => {
			if (timer) clearTimeout(timer);

			setState({ isConnectedToServer: true });
		},
		closed: () => {
			// Socket could be forcibly terminated by the client since WS params could be changed
			timer = setTimeout(() => {
				if (!getState().isConnectedToServer)
					setState({ isConnectedToServer: false });
			}, 5000);
		},
		error: () => {
			setState({ isConnectedToServer: false });
		},
		// connecting: () => {},
		// message: () => {},
		// opened: () => {},
		// ping: () => {},
		// pong: () => {},
	};
};
