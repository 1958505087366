import { useEffect, useState } from "react";

const hasFocus = () => typeof document !== "undefined" && document.hasFocus();

const useWindowFocus = () => {
	const [focused, setFocused] = useState(hasFocus); // Focus for first render

	useEffect(() => {
		setFocused(hasFocus()); // Focus for additional renders

		const onFocus = () => setFocused(true);
		const onBlur = () => setFocused(false);

		const handleVisibilityChange = () => {
			if (document.visibilityState === "hidden") onBlur();
			else onFocus();
		};

		window.addEventListener("focus", onFocus);
		window.addEventListener("blur", onBlur);
		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			window.removeEventListener("focus", onFocus);
			window.removeEventListener("blur", onBlur);
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	return focused;
};

export { useWindowFocus };
