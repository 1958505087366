import { tokens } from "locales/tokens";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useCountdown } from "usehooks-ts";
import { Dialog } from "~served/ui-comps";
import { useUserPermissions } from "~/hooks/useUserPermissions";
import { useWindowFocus } from "~/hooks/useWindowFocus";
import { browserStorage } from "~/libs/localforage";
import { useAuthCache } from "~/queries/useAuthCache";
import { useGetCurrentVenueCache } from "~/queries/useGetCurrentVenue";
import { getState, useLogout } from "~/store";
const LogoutModal = () => {
  const {
    reload,
    query,
    pathname
  } = useRouter();
  const {
    t
  } = useTranslation();
  const logout = useLogout();
  const {
    data: auth
  } = useAuthCache();
  const {
    data: venue
  } = useGetCurrentVenueCache();
  const permissions = useUserPermissions();
  const [isIdle, setIsIdle] = useState(false);
  // See: https://github.com/SupremeTechnopriest/react-idle-timer/pull/361
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    start: startIdleTimer,
    pause: pauseIdleTimer
  } = useIdleTimer({
    timeout: permissions.AUTO_LOGOUT_TIME,
    startManually: true,
    onIdle: () => setIsIdle(true),
    debounce: 1000
  });
  const isWindowFocus = useWindowFocus();
  const [isLoggedOutInOtherTab, setIsLoggedOutInOtherTab] = useState(false);
  const [isLoggedInToDiffVenue, setIsLoggedInToDiffVenue] = useState(false);
  const verifyUserSession = async () => {
    if (!auth) return;
    const cachedToken = await browserStorage.token.get();
    if (!cachedToken) return setIsLoggedOutInOtherTab(true);
    const cachedVenueId = await browserStorage.venueId.get();
    if (venue && cachedVenueId && cachedVenueId !== venue._id) return setIsLoggedInToDiffVenue(true);
  };
  useEffect(() => {
    void verifyUserSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWindowFocus]);
  useEffect(() => {
    if (!auth) {
      pauseIdleTimer();
      return;
    }
    if (pathname === "/" && query.tab === "prep_stations") {
      pauseIdleTimer();
      return;
    }
    startIdleTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, query, pathname]);
  const [count, {
    start,
    stop,
    reset
  }] = useCountdown({
    interval: 1000,
    seconds: 10
  });
  useEffect(() => {
    if (isIdle) start();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);
  useEffect(() => {
    if (count === 0) {
      stop();
      reset();
      setIsIdle(false);
      logout(pathname);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);
  const handleClose = () => {
    stop();
    reset();
    setIsIdle(false);
    setIsLoggedOutInOtherTab(false);
    setIsLoggedInToDiffVenue(false);
  };
  const handleRedirectToLogin = () => {
    window.$queryClient?.clear?.();
    getState().resetApp();
    handleClose();
  };
  if (isLoggedOutInOtherTab) return <Dialog dialogProps={{
    maxWidth: "xs"
  }} title="You were logged out" content="It appears you were logged out from somewhere else. If you have another tab open and unused for a while. That might be the cause." actionButtonProps={{
    children: "Log In Again",
    onClick: handleRedirectToLogin
  }} />;
  if (isLoggedInToDiffVenue) return <Dialog dialogProps={{
    maxWidth: "xs"
  }} title="Signed in to another venue" content="It appears this device / browser is signed in to another venue. To make sure you're seeing the correct data. Reload the page." actionButtonProps={{
    children: "Reload",
    onClick: () => reload()
  }} />;
  if (isIdle) return <Dialog dialogProps={{
    maxWidth: "xs"
  }} title="You're about to be logged out" content={`You've been inactive for a while. To protect your account's security, we're logging you out in ${count} seconds.`} actionButtonProps={{
    children: t(tokens.global.cancel),
    onClick: handleClose
  }} />;
  return null;
};
export { LogoutModal };