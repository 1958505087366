import { ErrorOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { tokens } from "locales/tokens";
import { useTranslation } from "react-i18next";
import { Dialog } from "~served/ui-comps";
import { useIsFeatureLockedDialogOpen, useSetIsFeatureLockedDialogOpen } from "~/store";
const FeatureRestrictionChecker = () => {
  const {
    t
  } = useTranslation();
  const isLocked = useIsFeatureLockedDialogOpen();
  const setIsFeatureLockedDialogOpen = useSetIsFeatureLockedDialogOpen();
  if (isLocked) return <Dialog content={<Box sx={{
    textAlign: "center",
    AlignItems: "center"
  }}>
						<ErrorOutlined sx={{
      fontSize: "120px",
      color: t => t.palette.primary.main
    }} />
						<Typography variant="body1" fontWeight="600">
							Feature is locked. Please upgrade your subscription plan.
						</Typography>
					</Box>} actionButtonProps={{
    children: t(tokens.global.close),
    onClick: () => setIsFeatureLockedDialogOpen(false)
  }} />;
  return null;
};
export { FeatureRestrictionChecker };