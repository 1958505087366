import { useQuery } from "@tanstack/react-query";

import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetTagsQuery, TAG_TYPE } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetTags {
		getTags {
			_id
			created_at
			updated_at
			label
			type
		}
	}
`;

export type GetTagsResults = GetTagsQuery["getTags"];

export const getTagsQueryKey = () => ["getTags"];

export const useGetTags = (enabled?) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getTagsQueryKey(),
		queryFn: () =>
			hubGqlClient.request(graphql(query)).then((res) => res.getTags),
	});
};

export const useGetTagsCache = () => {
	return useGetTags(false);
};

export const useLocationsGroupsTags = () => {
	const { data } = useGetTagsCache();

	return data ? data.filter((t) => t.type === TAG_TYPE.location_group) : [];
};

export const usePrinterTags = () => {
	const { data } = useGetTagsCache();

	return data ? data.filter((t) => t.type === TAG_TYPE.printer) : [];
};
